import React from "react"; 

const moaCopy = {
  pageTitle: 'Mechanism of Action for WELIREG™ (belzutifan)',
  introHeader: 'WELIREG is a selective HIF-2α inhibitor, which works in the nucleus and may reduce the transcription of certain target genes that drive tumor growth in VHL disease',
  introSubheader: '',
  introSubText: '',
  vidScript: <>
  <section>
  <p>In this video, we will explore the role of HIF-2α in VHL disease.</p>
  <p>In the cellular environment, hypoxia-inducible factor 2 alpha, or HIF-2α, is a protein responsible for adapting to low oxygen levels in normal cells and tumors. Under normal oxygen conditions, HIF-2α is hydroxylated.</p>
  <p>The VHL protein, or pVHL, is an essential regulator of HIF-2α.</p>
  <p>Under normal oxygen conditions, hydroxylated HIF-2α is recognized by pVHL and then targeted for degradation.</p>
  <p>In VHL disease, a germline mutation of the VHL gene causes pVHL impairment. Lack of functional VHL protein results in stabilization and accumulation of HIF-2α.</p>
  <p>Upon stabilization, HIF-2α translocates into the nucleus where it interacts with hypoxia-inducible factor 1 beta (HIF-1β).</p>
  <p>This interaction induces expression of downstream genes associated with cellular proliferation, angiogenesis, and tumor growth.</p>
  <p>Discover WELIREG (belzutifan), a HIF-2α inhibitor.</p>
  </section>
  <section>
  <p>WELIREG is indicated for the treatment of adult patients with von Hippel-Lindau (VHL) disease who require therapy for associated renal cell carcinoma (RCC), central nervous system (CNS) hemangioblastomas, or pancreatic neuroendocrine tumors (pNET), not requiring immediate surgery.</p>
  </section>
  <section>
  <p><strong>Selected Safety Information</strong></p>
  <p><strong>WARNING: EMBRYO-FETAL TOXICITY</strong></p>
  <ul>
    <li><strong>Exposure to WELIREG during pregnancy can cause embryo-fetal harm.</strong></li>
    <li><strong>Verify pregnancy status prior to the initiation of WELIREG.</strong></li>
    <li><strong>Advise patients of these risks and the need for effective non-hormonal contraception as
    WELIREG can render some hormonal contraceptives ineffective.</strong></li>
  </ul>
  </section>
  <section>
  <p><strong>Anemia</strong></p>
  <ul>
    <li className="has-sub-menu">
      <ul>WELIREG can cause severe anemia that can require blood transfusion.
          <li>Monitor for anemia before initiation of, and periodically throughout, treatment. Transfuse patients as clinically indicated. For patients with hemoglobin &lt;8 g/dL, withhold WELIREG until ≥8 g/dL, then resume at the same or reduced dose or permanently discontinue WELIREG, depending on the severity of anemia. For life-threatening anemia or when urgent intervention is indicated, withhold WELIREG until hemoglobin ≥8 g/dL, then resume at a reduced dose or permanently discontinue WELIREG.</li>
      </ul>
    </li>
    <li>In LITESPARK-004 (N=61), decreased hemoglobin occurred in 93% of patients with VHL disease and 7% had Grade 3 events. Median time to onset of anemia was 31 days (range: 1 day to 8.4 months).</li>
    <li>The safety of erythropoiesis-stimulating agents (ESAs) for treatment of anemia in patients with VHL disease treated with WELIREG has not been established.</li>
  </ul>
  </section>
  <section>
  <p><strong>Hypoxia</strong></p>
  <ul>
    <li>WELIREG can cause severe hypoxia that may require discontinuation, supplemental oxygen, or hospitalization.</li>
    <li>Monitor oxygen saturation before initiation of, and periodically throughout, treatment. For
    decreased oxygen saturation with exercise (eg, pulse oximeter &lt;88% or PaO<sub>2</sub> ≤55 mm Hg),
    consider withholding WELIREG until pulse oximetry with exercise is greater than 88%, then
    resume at the same or a reduced dose. For decreased oxygen saturation at rest (eg, pulse
    oximeter &lt;88% or PaO<sub>2</sub> ≤55 mm Hg) or when urgent intervention is indicated, withhold
    WELIREG until resolved and resume at a reduced dose or discontinue. For life-threatening
    or recurrent symptomatic hypoxia, permanently discontinue WELIREG. Advise patients to
    report signs and symptoms of hypoxia immediately to a health care provider.</li>
      <li>In LITESPARK-004, hypoxia occurred in 1.6% of patients.</li>
  </ul>
  </section>
  <section>
  <p><strong>Embryo-Fetal Toxicity</strong></p>
  <ul>
    <li>Based on findings in animals, WELIREG can cause fetal harm when administered to a
    pregnant woman.</li>
    <li>Advise pregnant women and females of reproductive potential of the potential risk to the
    fetus. Advise females of reproductive potential to use effective non-hormonal
    contraception during treatment with WELIREG and for 1 week after the last dose. WELIREG
    can render some hormonal contraceptives ineffective. Advise male patients with female
    partners of reproductive potential to use effective contraception during treatment with
        WELIREG and for 1 week after the last dose.</li>
  </ul>
  <br/>
  <p>WELIREG is an inhibitor of HIF-2α.</p>
  <p>WELIREG binds to HIF-2α via the PAS-B domain, and in conditions of hypoxia or impairment of VHL protein function, WELIREG blocks the HIF-2α–HIF-1β interaction, leading to reduced transcription and expression of HIF-2α target genes associated with cellular proliferation, angiogenesis, and tumor growth.</p>
  </section>
  <section>
  <p><strong>Adverse Reactions</strong></p>
  <ul>
    <li>In LITESPARK-004, serious adverse reactions occurred in 15% of patients, including anemia, hypoxia, anaphylaxis reaction, retinal detachment, and central retinal vein occlusion (1 patient each).</li>
    <li>WELIREG was permanently discontinued due to adverse reactions in 3.3% of patients for dizziness and opioid overdose (1.6% each).</li>
    <li>Dosage interruptions due to an adverse reaction occurred in 39% of patients. Those which required dosage interruption in &gt;2% of patients were fatigue, decreased hemoglobin, anemia, nausea, abdominal pain, headache, and influenza-like illness.</li>
    <li>Dose reductions due to an adverse reaction occurred in 13% of patients. The most frequently reported adverse reaction which required dose reduction was fatigue (7%).</li>
    <li>The most common adverse reactions (≥25%), including laboratory abnormalities, that occurred in patients who received WELIREG were decreased hemoglobin (93%), fatigue (64%), increased creatinine (64%), headache (39%), dizziness (38%), increased glucose (34%), and nausea (31%).</li>
  </ul>
  </section>
  <section>
  <p><strong>Drug Interactions</strong></p>
  <ul>
    <li>Coadministration of WELIREG with inhibitors of UGT2B17 or CYP2C19 increases plasma exposure of belzutifan, which may increase the incidence and severity of adverse reactions. Monitor for anemia and hypoxia and reduce the dosage of WELIREG as recommended.</li>
    <li>Coadministration of WELIREG with CYP3A4 substrates decreases concentrations of CYP3A4 substrates, which may reduce the efficacy of these substrates or lead to therapeutic failures. Avoid coadministration with sensitive CYP3A4 substrates. If coadministration cannot be avoided, increase the sensitive CYP3A4 substrate dosage in accordance with its Prescribing Information. Coadministration of WELIREG with hormonal contraceptives may lead to contraceptive failure or an increase in breakthrough bleeding.</li>
  </ul>
  </section>
  <section>
  <p><strong>Lactation</strong></p>
  <ul>
    <li>Because of the potential for serious adverse reactions in breastfed children, advise women
    not to breastfeed during treatment with WELIREG and for 1 week after the last dose.</li>
  </ul>
  </section>

  <section>
  <p><strong>Females and Males of Reproductive Potential</strong></p>
  <ul>
    <li>WELIREG can cause fetal harm when administered to a pregnant woman. Verify the
    pregnancy status of females of reproductive potential prior to initiating treatment with   
    WELIREG.</li>
    <li>Use of WELIREG may reduce the efficacy of hormonal contraceptives. Advise females of
    reproductive potential to use effective non-hormonal contraception during treatment with
    WELIREG and for 1 week after the last dose. Advise males with female partners of
    reproductive potential to use effective contraception during treatment with WELIREG and
    for 1 week after the last dose.</li>
    <li>Based on findings in animals, WELIREG may impair fertility in males and females of
    reproductive potential and the reversibility of this effect is unknown.</li>
  </ul>
  </section>
  <section>
  <p><strong>Pediatric Use</strong></p>
  <ul>
    <li>Safety and effectiveness of WELIREG in pediatric patients under 18 years of age have not
    been established.</li>
  </ul>
  </section>
    <p><strong>Before prescribing WELIREG, please read the accompanying <a target="__blank" href="https://www.merck.com/product/usa/pi_circulars/w/welireg/welireg_pi.pdf">Prescribing Information</a>, including
    the Boxed Warning about embryo-fetal toxicity. The <a target="__blank" href="https://www.merck.com/product/usa/pi_circulars/w/welireg/welireg_mg.pdf">Medication Guide</a> also is available.</strong></p>
  </>,

  introFrameHeader: 'See the following images for a summary of the mechanism of action for WELIREG',
  frame1Text: 'In VHL disease, VHL protein function is impaired while HIF-2α stabilizes',
  frame2Text: 'Lack of functional VHL protein results in accumulation of HIF-2α. HIF-2α translocates into the nucleus and interacts with hypoxia-inducible factor 1 beta (HIF-1β)',
  frame3Text: 'This interaction induces expression of downstream genes leading to associated activity',
  frame4Text: 'WELIREG blocks HIF-2α–HIF-1β interaction, leading to reduced expression of target genes associated with cellular proliferation, angiogenesis, and tumor growth',

  binding: 'WELIREG binds to HIF-2α and, in conditions of hypoxia or impairment of VHL protein function, blocks HIF-2α–HIF-1β interaction',
  bindingSubText: 'VHL = von Hippel-Lindau; HIF-1β = hypoxia-inducible factor 1 beta.',
  
  references: [
    'Narayan V, Jonasch E. Systemic therapy development in Von Hippel-Lindau Disease: an outsized contribution from an orphan disease. <em>Cancers (Basel)</em>. 2022;14(21):5313.  ',
  ],

  buttonBlade: {
    title: 'Learn more about these attributes of WELIREG:',
    buttonLabelLeft: 'Adverse Reaction Profile',
    buttonHrefLeft: '/von-hippel-lindau/adverse-reaction-profile',
    buttonLabelMid: 'Dosing',
    buttonHrefMid: '/von-hippel-lindau/dosing',
    buttonLabelRight: 'Efficacy',
    buttonHrefRight: '/von-hippel-lindau/efficacy',
  },

};

export default moaCopy;
