import React, { useState } from 'react';
// Components
import PageSeo from '../../components/Seo/PageSeo';
import PageTitle from '../../components/PageTitle';
import PageSection from '../../components/PageSection';
import * as Text from '../../styles/shared/typography';
import * as s from '../../styles/pages/mechanism-of-action';
import { respondTo } from '../../styles/shared/breakpoints';
import * as colors from '../../styles/shared/colors';
import { Image } from '../../components';
// Content
import moaCopy from '../../copy/mechanism-of-action';
import ViewScript from '../../assets/images/icon-transcriptview.png';
import CloseScript from '../../assets/images/icon-transcriptclose.png';
import styled from 'styled-components';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import { CalloutBanner, PageSeparator, TextBlock } from '../../components';
import MechImage from '../../assets/images/mechanism-of-action.webp'
import MechImageMobile from '../../assets/images/mechanism-of-action-mobile.png'

const pageMeta = {
  title: 'Mechanism of Action for WELIREG™ (belzutifan) | Von Hippel-Lindau Disease',
  keywords: 'mechanism of action for welireg in vhl disease, mechanism of action for belzutifan in vhl disease',
  description: 'Health care professionals can view graphics and a video that illustrates the mechanism of action (MOA) for WELIREG™ (belzutifan) in patients with von Hippel-Lindau (VHL) disease–associated tumors.',
  schemaJsonLD: [
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/von-hippel-lindau/mechanism-of-action/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/von-hippel-lindau/mechanism-of-action/","@type":"VideoObject","name":"Mechanism of Action for WELIREG™ (belzutifan)","description":"In this video, we will explore the role of HIF-2α in VHL disease.","duration":"10M29S","transcript":"In this video, we will explore the role of HIF-2α in VHL disease. In the cellular environment, hypoxia-inducible factor 2 alpha, or HIF-2α, is a protein responsible for adapting to low oxygen levels in normal cells and tumors. Under normal oxygen conditions, HIF-2α is hydroxylated. The VHL protein, or pVHL, is an essential regulator of HIF-2α. Under normal oxygen conditions, hydroxylated HIF-2α is recognized by pVHL and then targeted for degradation. In VHL disease, a germline mutation of the VHL gene causes pVHL impairment. Lack of functional VHL protein results in stabilization and accumulation of HIF-2α. Upon stabilization, HIF-2α translocates into the nucleus where it interacts with hypoxia-inducible factor 1 beta (HIF-1β). This interaction induces expression of downstream genes associated with cellular proliferation, angiogenesis, and tumor growth. Discover WELIREG (belzutifan), a HIF-2α inhibitor. WELIREG is indicated for the treatment of adult patients with von Hippel-Lindau (VHL) disease who require therapy for associated renal cell carcinoma (RCC), central nervous system (CNS) hemangioblastomas, or pancreatic neuroendocrine tumors (pNET), not requiring immediate surgery.","thumbnailURL":"https://cf-images.us-east-1.prod.boltdns.net/v1/static/1905768940001/2b63f4e5-8966-4771-a0a6-0db29d2bf95a/0a6c3fff-10ca-48ff-a4a5-d94484445e91/1280x720/match/image.jpg","embedURL":"https://players.brightcove.net/1905768940001/meAxH3mZK_default/index.html?videoId=6348974238112","uploadDate":"2024-04-02"}`,
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/von-hippel-lindau/mechanism-of-action/","@type":"Drug","proprietaryName":"WELIREG™","nonProprietaryName":"belzutifan","mechanismofAction":"WELIREG is a selective HIF-2α inhibitor, which works in the nucleus and may reduce the transcription of certain target genes that drive tumor growth in VHL disease","manufacturer":"Merck","prescribingInfo":"https://www.merck.com/product/usa/pi_circulars/w/welireg/welireg_pi.pdf"}`
  ]
}

const Spacer60 = styled.div`
  height: 60px;
`;

const MechImageCont = styled(Image)`
padding: 8px;
  img {
    width: 100%;
  }
`;
const BlueTextBlock = styled.div`
  background-color: ${colors.tintsBlueLt2};
  border-radius: 8px;
  padding: 12px;
  margin-top: 16px;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;

  p {
    color: ${colors.brandBlueDark};
    margin-bottom: 0;
    max-width: 892px;
  }

  ${respondTo.sm`
    padding: 12px 40px;
  `}

`;

const MOATextBlock = styled(TextBlock)`
  b{
    font-weight: 600;
  }
  i {
    font-style: italic;
  }
`;
export const VideoContainer = styled.div`
  display: block;
  min-height: 300px;
  .video-js {
    width: 100%;
    max-height: 562px;
    min-height: 300px;
    @media (min-width: 768px) {
      min-height: 562px;
    }
  }
`;

const StandardSection = styled(PageSection)`
  display: flex;
  justify-content: center;
  padding: 0;
`;

const firstAndOnlyTextBlock = {

  body: <>
    <p>As a selective HIF-2α inhibitor, WELIREG is the only approved systemic tumor management option for patients with certain VHL disease–associated tumors<sup>1,2</sup></p>
  </>
}

const MoaPage = () => {
  const calloutBanner = {
    title: "Learn more about these attributes of WELIREG:",
    continualInvertedButtons: true,
    links: [
      {
        label: "Safety",
        url: "/von-hippel-lindau/adverse-reaction-profile/",
      },
      {
        label: "Dosing",
        url: "/von-hippel-lindau/dosing",
      },
      {
        label: "Efficacy",
        url: "/von-hippel-lindau/efficacy/",
      }
    ]
  };
  
  let reactPlayerLoader;
  const onSuccess = function(success) {
  
    // The player object or iframe element (depending on embed type) can be
    // accessed in two ways.
    // 
    // From the success object passed to the `onSuccess` callback:
    // console.log(success.ref);
  
    // As a property of the component instance:
    // console.log(reactPlayerLoader.player);
  };
  
  const [toggle, setToggle] = useState(false)

  return (
    <>
      <PageSeo pageMeta={pageMeta} >
        <link rel="canonical" href="https://www.welireghcp.com/von-hippel-lindau/mechanism-of-action/" />
      </PageSeo>

      <PageTitle>
        {moaCopy.pageTitle}
      </PageTitle>

      <PageSection reg>
        <s.IntroHeaderWrap>
          <TextBlock
          title={moaCopy.introHeader}
          />
        </s.IntroHeaderWrap>

        <s.VidWrapper>
          <VideoContainer>
            <ReactPlayerLoader  accountId='1905768940001' videoId='6348974238112' playerId='meAxH3mZK' onSuccess={onSuccess}/>
          </VideoContainer>

          <button className='view-trans'
            onClick={() => setToggle(!toggle)}>
              {toggle ? (<><img src={CloseScript}/>Close</>) : (<><img alt='Expand Video Transcript for the Mechanism of Action of WELIREGTM (belzutifan)' src={ViewScript}/>View</>)} transcript
          </button>
          <s.IntroSubText dangerouslySetInnerHTML={{__html: moaCopy.introSubText}} />
          {toggle && (
            <s.VidTranscript >
              {moaCopy.vidScript}
              </s.VidTranscript>
          )}
        </s.VidWrapper>
      </PageSection>


      <StandardSection>
          <MechImageCont 
            maxWidth="100%"
            title="See the following images for a summary of the mechanism of action for WELIREG"
            subtext=""
            graphTitle=""
            imgPath={MechImage}
            mobImgPath={MechImageMobile}
            imgAlt="Mechanism of Action for WELIREG™ (belzutifan): WELIREG Binds to HIF-2α, Which Blocks Interaction With HIF-1β and May Reduce Downstream Activities of Cellular Proliferation Angiogenesis, and Tumor Growth"
           
          />
        <BlueTextBlock>
            {firstAndOnlyTextBlock.body}
        </BlueTextBlock>
        <PageSeparator topPadding='16px' topPaddingMobile='16px' />


      <PageSection short btmFlat>
        <s.Footnote>
          <Text.Footnote>
            {moaCopy.bindingSubText}
          </Text.Footnote>
        </s.Footnote>
      </PageSection>


        <MOATextBlock
          footer={{ definitions: <><b>References: 1.</b> Narayan V, Jonasch E. Systemic therapy development in von Hippel-Lindau disease: an outsized contribution from an orphan disease. <em>Cancers (Basel)</em>. 2022;14(21):5313. <strong>2.</strong> Center for Drug Evaluation and Research. <em>Advancing Health Through Innovation: New Drug Therapy Approvals 2021.</em> https://www.fda.gov/media/155227/download. Accessed February 6, 2024.</>}}
        >
        </MOATextBlock>

      </StandardSection>

      <Spacer60 />

      <CalloutBanner {...calloutBanner} />
    </>
  );
};

export default MoaPage;
