import styled from 'styled-components';
import { respondTo } from '../shared/breakpoints';
import { weliregBlue, greylight, white, pebble2, ruby } from '../shared/colors';
import { unorderedListCss } from '../shared/typography';

export const IntroHeaderWrap  = styled.div`
  *:last-child {
    margin-bottom: 0;
  }
  [class*='TextBlock__Container-'] h3 { 
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 28px;
  }
`
export const SubHeadWrap  = styled.div`
  ${respondTo.sm`
    padding: 0 190px 0 0;
  `}
`
export const ImageTextWrap = styled.div`
  ${respondTo.sm`
    padding: 0 15px 0 0;
  `}
`

export const IntroSubText  = styled.div`
  padding-bottom: 20px;
`;

export const VidWrapper = styled.div`
  display: inline-grid;
  width: 100%;

  .vjs-fluid {
    padding-top: 56.25%;
  }
  .view-trans {
    padding: 15px 0;
    color: ${ruby};
    font-size: 16px;
    background: transparent;
    border: 0;
    display: inline-flex;
    justify-content: flex-end;
    cursor: pointer;
    img {
      top: -2px;
      position: relative;
      padding-right: 10px;
    }
  }
`;

export const VidTranscript = styled.div`
  background-color: ${white};
  padding: 13px 7px 14px 28px;
  font-size: 16px;
  line-height: 1.3;
  border-radius: 4px;
  border: 1px solid ${pebble2};
  height: 180px;
  overflow-y: scroll;
  a {
    color: ${ruby};
  }
  p, p.no-margin {
    margin-bottom: 1em;
  }
  ul {
    ${unorderedListCss}
    .has-sub-menu {
      li {
        margin-bottom: 1em;
        margin-top: 1em;
      }
    }
  }
  section {
    margin-bottom: 2em;
  }
`;

export const RoundGrey = styled.div`
  border-radius: 8px;
  background-color: #E2EDF7;
  overflow: hidden;
  max-width: 800px;
  margin: 0 auto;
  margin-left: 0;
  h4 {
    color: ${weliregBlue};
    padding: 20px;
    font-weight: 500;
  }
`;

export const RoundBlue = styled.div`
  border-radius: 8px;
  background: linear-gradient(90deg, #2E3790 12.56%, #3F89C8 100%);
  overflow: hidden;
  max-width: 800px;
  margin: 0 auto;
  margin-left: 0;
  h4 {
    color: ${white};
    padding: 20px;
    font-weight: 500;
  }
`;

export const Footnote = styled.div`
  max-width: 800px;
  margin: 0 auto;
  margin-left: 0;
`;
